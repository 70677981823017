<template>
  <div id="sales-reports-view" class="sales-reports-container">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" title="Reports">
      <template v-slot:right-end>
<!--        <vs-checkbox class="px-2"-->
<!--                     v-if="sales.length > 0"-->
<!--                     v-model="showDetails">Show Details</vs-checkbox>-->
        <vs-button v-if="sales.length > 0 && !errorFetching" class="mt-3 py-4 px-3 mr-4" color="primary"
                   @click="showPinBatch = !showPinBatch">{{ !showPinBatch ? $t('ShowDetails') : $t('HideDetails') }}
        </vs-button>

        <vs-button v-if="sales.length > 0 && !errorFetching" class="mt-3 py-4 px-3 mr-4" color="primary"
                   @click="onOpenDownloadEmailModal(false)">{{ $t('DownloadCSV') }}</vs-button>
        <vs-button v-if="sales.length > 0 && !errorFetching" class="mt-3 py-4 px-3" color="primary"
                   @click="onOpenDownloadEmailModal(true)">{{ $t('DownloadExcel') }}</vs-button>
        <vs-button @click="viewAllReportData()"
                   class="mt-3 py-4 px-3 ml-4" color="primary">
          {{ viewAll ? $t('ShowLess') : $t('ViewAll') }}
        </vs-button>
      </template>
    </breadcrumb-base>


    <transition name="fade">
      <div class="vx-row">
        <div class="vx-col md:w-1/5 mt-4">
          <vx-card>

            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="clearFilterData">{{$t('ClearAllFilters').toUpperCase()}}
            </vs-button>
            <div class="py-2"></div>
            <div class="vs-col ">

              <dynamic-customers-drop-down
                :show-clear="true"
                :placeholder="$t('SearchCustomer')"
                :selected-item="customer || {}"
                @on-item-selected="onCustomerFilterChanged">
              </dynamic-customers-drop-down>
              <div class="py-2"></div>
            </div>
            <div class="vx-col mt-2 w-full"
                 v-if="showMangerDropDown">

              <label style="font-size: 12px; font-weight: bold">{{ $t('Manager') }}
                <span
                  @click="selectedManager = null;filterChanged()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <vs-select
                autocomplete
                class=" w-full "
                v-model="selectedManager"
                @input="onProductFilterChanged(-1)"
                :placeholder="$t('Manager')"
              >
                <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
                <vs-select-item :key="index" :value="customer" :text="customer.user_name"
                                v-for="(customer,index) in usersRaw.manager_accounts"/>
              </vs-select>
            </div>

            <div class="vx-col mt-2 w-full"
                 v-if="showRefererDropDown">

              <label style="font-size: 12px; font-weight: bold">{{ $t('Staff') }}
                <span
                  @click="selectedReferer = null;filterChanged()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <vs-select
                autocomplete
                class=" w-full "
                v-model="selectedReferer"
                @input="onProductFilterChanged"
                :placeholder="$t('Staff')"
              >
                <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
                <vs-select-item :key="index" :value="customer.user_id" :text="customer.user_name"
                                v-for="(customer,index) in staffAccounts"/>
              </vs-select>
            </div>


            <div class="vs-col w-full"
                 v-if="productsFilter.length > 0">
              <label style="font-size: 12px; font-weight: bold">{{ $t('Product') }}
                <span
                  @click="selectedProduct = null;filterChanged()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <vs-select
                autocomplete
                class="pr-4 w-full"
                v-model="selectedProduct"
                v-if="productsFilter.length > 0"
                @input="onProductFilterChanged"
              >

                <vs-select-item :key="index" :value="product"
                                :text="product.product_name" v-for="(product,index) in productsFilter"/>
              </vs-select>
              <div class="py-2"></div>
            </div>
            <div class="vs-col mb-4 w-full"
                 v-if="productsFilter.length > 0 && selectedProduct">
              <label style="font-size: 12px; font-weight: bold">{{ $t('Denomination') }}
                <span
                  @click="selectedProductConfigId = null;filterChanged()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <vs-select
                autocomplete
                class="pr-4 w-full"
                v-model="selectedProductConfigId"
                v-if="productsFilter.length > 0"
                @input="onProductFilterChanged"
              >
                <template v-for="(product) in selectedProduct.configurations">

                    <vs-select-item
                                    :value="product.product_configuration_id + 'product_configuration_id'"
                                    :text="product.product_configuration_value | germanNumberFormat"/>
                </template>
              </vs-select>
            </div>
            <div class="py-4"></div>
            <div class="vx-col mt-4 m-3"
                 :key="index"
                 v-for="(item, index) in Object.keys(salesFilters)">
              <label style="font-size: 12px; font-weight: bold">
                {{ item | title }}
                <span
                  @click="filterSelectData[item] = null;filterChanged()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <ul class="centerx mt-4">
                <li
                  :key="index"
                  v-for="(option, index) in salesFilters[item]">
                  <vs-radio v-model="filterSelectData[item]" v-on:change="filterChanged"
                            :vs-value="option.id.toString() + item">{{ option.name | title }}
                  </vs-radio>
                </li>
              </ul>
              <vs-divider/>
            </div>
            <!--            From TO Date Filters -->
            <!--            <h4>{{ $t('FromDate') | title }}</h4>-->
            <div class="py-2"></div>
            <label style="font-size: 12px; font-weight: bold">{{ $t('FromDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('FromDate')"
                        :disabled-dates="{from: new Date()}"
                        v-model="startDate" v-on:select="filterChanged"></datepicker>
            <div class="py-2"></div>
            <!--            <h4>{{ $t('ToDate') | title }}</h4>-->
            <div class="py-2"></div>
            <label style="font-size: 12px; font-weight: bold">{{ $t('ToDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('ToDate')"
                        v-model="endDate" :disabled-dates="disabledDates"></datepicker>
            <div class="py-2"></div>


            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="filterChanged">{{ $t('Filter').toUpperCase() }}
            </vs-button>
            <div class="py-2"></div>
            <!--            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"-->
            <!--                       @click="clearFilterData">{{ $t('ClearAllFilters').toUpperCase() }}-->
            <!--            </vs-button>-->
          </vx-card>
        </div>
        <div class="vx-col md:w-4/5">

          <!--    Error List State-->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
                <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
                     class="mx-auto mb-4 max-w-full">
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
              </div>
            </div>
          </transition>
          <!--    Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="sales.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
              </div>
            </div>
          </transition>
          <vs-table-modified v-show="sales.length > 0 && !errorFetching" ref="table"
                             :max-items="20"
                             :data="sales"
          >
            <!--                             v-on:selected="openProductDetailPage"-->
            <template slot="header">
              <table class="header-table" style="width: auto;" v-if="salesRaw">
                <thead>
                <th class="text-center" v-if="activeUserInfo.account_role === 0">
                  {{ $t('TotalBuyingAmount') }}
                </th>
                <th class="text-center" v-if="activeUserInfo.account_role === 1 && activeUserInfo.internal_role == 0">
                  {{ $t('TotalBuyingAmount') }}
                </th>
                <th class="text-center">
                  {{ $t('TotalSaleAmount') }}
                </th>
                <th class="text-center" v-if="activeUserInfo.account_role === 0">
                  {{ $t('Profit') }}
                </th>
                <th class="text-center" v-if="activeUserInfo.account_role === 1 && activeUserInfo.internal_role == 0">
                  {{ $t('Profit') }}
                </th>
                <th class="text-center">
                  {{ $t('Quantity') }}
                </th>
                </thead>
                <tbody>
                <tr>
                  <td class="text-center" v-if="activeUserInfo.account_role === 0">
                    <span>{{ salesCounters.total_buying_price | germanNumberFormat4 }}</span>
                  </td>
                  <td class="text-center" v-if="activeUserInfo.account_role === 1 && activeUserInfo.internal_role == 0">
                    <span>{{ salesCounters.total_buying_amount | germanNumberFormat4 }}</span>
                  </td>
                  <td class="text-center">
                    <span>{{ salesCounters.total_sale_amount | germanNumberFormat4 }}</span>
                  </td>
                  <td class="text-center" v-if="activeUserInfo.account_role === 0">
                    <span>{{ salesCounters.total_profit | germanNumberFormat4 }}</span>
                  </td>
                  <td class="text-center" v-if="activeUserInfo.account_role === 1 && activeUserInfo.internal_role == 0">
                    <span>{{ salesCounters.total_profit | germanNumberFormat4 }}</span>
                  </td>
                  <td class="text-center">
                    <span>{{ salesCounters.total_quantity }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </template>
            <template slot="thead">
              <vs-th >
                <div class="">
                  <p>{{ $t('SoldDate') }}</p>
                  <p>{{ $t('IPAddress') }}</p>
                </div>
              </vs-th>
              <vs-th >{{ $t('Product') }}</vs-th>
              <vs-th >{{ $t('Quantity') }}</vs-th>

              <vs-th class="" v-if="activeUserInfo.account_role !== 1 && activeUserInfo.internal_role != 0">
                {{ $t('BuyingPrice') }}
              </vs-th>
              <vs-th class="">{{ $t('SellingPrice') }}</vs-th>
              <vs-th class="" v-if="activeUserInfo.account_role === 0">{{ $t('Profit') }}</vs-th>
<!--              <vs-th v-if="activeUserInfo.account_role === 1">{{ $t('SellerProfit') }}</vs-th>-->
              <vs-th v-if="activeUserInfo.account_role === 1 && activeUserInfo.internal_role == 0">{{ $t('ResellerProfit') }}</vs-th>
<!--              <vs-th >{{ $t('Customer') }}</vs-th>-->
              <vs-th >{{ $t('Organisation') }}</vs-th>
              <vs-th v-if="showDetails">{{ $t('Details') }}</vs-th>
              <template v-if="showPinBatch">
                <vs-th >{{ $t('Pin') }}</vs-th>
                <vs-th >{{ $t('Batch') }}</vs-th>
              </template>
              <vs-th >{{ $t('Details') }}</vs-th>
            </template>

            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">


                <vs-td>
                  <div class="">
                    <p class="product-name font-medium truncate" >{{ tr.created | dateFormat }}</p>
                    <p class="product-name"
                       style="font-size: 12px;"
                    >{{ tr.ip_address }}</p>
                  </div>
                </vs-td>

                <vs-td>
                  <p class="product-name">{{ tr.product | title }} <span v-if="tr.value != null"> - {{ tr.value | germanNumberFormat }}</span></p>
                </vs-td>

                <vs-td>
                  <p class="product-name ">{{ tr.quantity }}</p>
                </vs-td>

                <vs-td v-if="activeUserInfo.account_role !== 1 && activeUserInfo.internal_role != 0">
                  <p class="product-name" >{{
                      tr.buying_price  | germanNumberFormat4
                    }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name">{{ tr.selling_price | germanNumberFormat4 }}</p>
                </vs-td>

                <vs-td v-if="activeUserInfo.account_role === 0">
                  <p class="product-name">{{ tr.profit | germanNumberFormat4 }}</p>
                </vs-td>
                <vs-td v-if="activeUserInfo.account_role === 1 && activeUserInfo.internal_role == 0">
                  <p class="product-name">{{ tr.reseller_profit | germanNumberFormat4 }}</p>
                </vs-td>
<!--                <vs-td>-->
<!--                  <div class="vs-col">-->
<!--                    <p>{{ tr.customer_id }}</p>-->
<!--                    <p class="truncate">{{ (tr.organisation || tr.organisation_name) | capitalize }}</p>-->
<!--                  </div>-->
<!--                </vs-td>-->
                <vs-td>
                  <vx-tooltip >
                    <template slot="tip">
                      <div class="vs-col" >
                        <p><strong>{{ $t('CustomerID') }}: {{ tr.customer_id }}</strong></p>
                      </div>
                    </template>

                    <div class="vs-col">
                      <p class="truncate">
                        {{ (tr.organisation || tr.organisation_name) | capitalize }}
                        <strong>({{ tr.customer_id }})</strong>
                      </p>
                      <p class="truncate" style="max-width: 150px;">
                        {{ tr.organisation_first_name || tr.first_name }} {{ tr.organisation_last_name || tr.last_name }}
                      </p>
                      <p style="font-size: 11px; color: black">
                        {{ tr.address }}<span v-if="tr.house_no"> {{ tr.house_no }}</span>
                      </p>
                      <p style="font-size: 11px; color: black">
                        {{ tr.postal }} {{ tr.city }}
                      </p>
                    </div>
                  </vx-tooltip>
                </vs-td>
                <template v-if="showPinBatch">
                  <vs-td >
                    <div class="flex flex-col">
                      <p class="product-name"
                         v-if="tr.dynamic_type == 5"
                         >{{ tr.json_data.payment_id }}</p>
                      <p class="product-name"
                         v-if="tr.dynamic_type != 5"
                         v-for="(pB, index) in tr.json_data.pins_list || tr.json_data.transaction_pins">{{ pB.pin }}</p>
                    </div>
                  </vs-td>
                  <vs-td >
                    <div class="flex flex-col">
                      <p class="product-name"
                         v-if="tr.dynamic_type == 5"
                      >{{ tr.json_data.ticket_for_order.order.id }}</p>
                    <p
                      v-if="tr.dynamic_type != 5"
                      v-for="(pB, index) in tr.json_data.pins_list || tr.json_data.transaction_pins" class="product-name">{{ pB.batch }}</p>
                    </div>
                  </vs-td>
                </template>
                <vs-td v-if="showPinBatch">
                  <a
                    target="_blank"
                    :href="tr.json_data.ticket_for_order.order.invoice_link"
                    v-if="tr.dynamic_type == 5 && activeUserInfo.account_role != 2" >
                    <vs-button>{{ $t('Details') }}</vs-button>
                  </a>
                  <vs-button v-if="tr.dynamic_type != 5"  @click="showPinsModal(tr)">{{ $t('View') }}</vs-button>
                </vs-td>

                <vs-td >
                  <template v-if="tr.is_bulk_transaction">
                    <a
                      v-if="tr.bulk_transaction_csv_file"
                      :href="tr.bulk_transaction_csv_file" download>
                      <vs-button
                        class="ml-2">{{$t('DownloadTXT') }}</vs-button>
                    </a>
                  </template>
                  <template v-else>
                    <vs-button
                      class="ml-2"
                      v-if="activeUserInfo.account_role === 1 && tr.is_reclamation"
                      color="danger"
                      @click="reclaimPins(tr)" >{{ $t('Reclaim') }}</vs-button>
                  </template>
                  <vs-button
                    class="ml-2 whitespace-nowrap"
                    v-if="activeUserInfo.account_role === 0"
                    color="danger"
                    @click="showSessionDetails(tr)" >{{ $t('SessionDetails') }}</vs-button>
                </vs-td>

              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="cPage" v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>


    <vx-modal v-if="showDetailsModal && pins.length > 0" component-class="modal-container-fit">
      <template slot="body">
        <div class="p-4 text-center">
          <table class="pins-table">
            <tr>
              <th>{{ $t('Pins') }}</th>
              <th>{{ $t('Batch') }}</th>
            </tr>
            <tr v-for="(d, index) in pins">
              <td>{{ d.pin }}</td>
              <td>{{ d.batch }}</td>
            </tr>
          </table>

          <vs-button class="mt-3" color="danger" @click="closePinsModal">{{ $t('Close') }}</vs-button>
        </div>
      </template>
    </vx-modal>

    <vx-modal v-if="showPrintModal" component-class="modal-container-fit">
      <template slot="body">
        <div v-if="purchaseData">

          <receipt-print
            v-on:close="closeModal()"
            ref="printCard"
            :is-duplicate="true"
            :print-report-type="printReportType"
            :purchase-data="purchaseData"></receipt-print>
        </div>
      </template>
    </vx-modal>

    <vx-modal v-if="showReclaimPinsModal" component-class="modal-container-fit">
      <template slot="body">
        <div v-if="purchaseData">
          <div style="min-height: 200px; min-width: 300px; overflow-y: auto;">
            <div class="text-center status-card" >
              <div v-if="purchaseData">

                <reclaim-pins
                  v-on:close="closeModal()"
                  :print-report-type="printReportType"
                  :purchase-data="purchaseData" ></reclaim-pins>
              </div>
            </div>

          </div>

        </div>
      </template>
    </vx-modal>

    <vx-modal v-if="showDownloadEmailModal" component-class="modal-container-fit">
      <template slot="body">
        <div style="min-width: 300px; overflow-y: auto;">
          <div class="text-center status-card" >
            <div class="flex flex-col py-4 space-y-4">
              <h1>
                {{ $t('GetSalesReportToEmail') }}
              </h1>
              <div class="">

                <vs-input class="p-3 w-full"
                          :placeholder="$t('EmailId')" v-model="downloadEmail"/>
              </div>
              <div class="flex flex-row">
                <vs-button
                           class="ml-2"
                           @click="onCloseDownloadEmailModal" color="danger">
                  {{ $t('Cancel') }}
                </vs-button>
                <vs-button
                  class="ml-2"
                  @click="onCloseDownloadEmailModal(downloadEmail)" >{{ $t('Download') }}</vs-button>
              </div>
            </div>
          </div>

        </div>
      </template>
    </vx-modal>

    <!-- Session Details Modal -->
    <vx-modal v-if="showSessionDataModal" component-class="modal-container-fit">
      <template slot="body">
        <div style="min-width: 300px; overflow-y: auto;">
          <div class="text-center status-card" >
            <div class="flex flex-col py-4 space-y-4">
              <h1 class="text-2xl font-bold">
                {{ $t('BrowserPrintDetails') }}
              </h1>
              <div class="">
                <table class="w-full my-4 top-details-table session-data-table">
                  <tr>
                    <td>
                      <p>{{ $t('User') }} : </p>
                    </td>
                    <td>
                      <p>{{ salesSessionData.browser_print_details.user }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{{ $t('AccessType') }} : </p>
                    </td>
                    <td>
                      <p>{{ salesSessionData.browser_print_details.access_type_text }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{{ $t('SessionCreated') }} : </p>
                    </td>
                    <td>
                      <p>{{ salesSessionData.browser_print_details.session_created | dateFormat }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{{ $t('ApprovedStatus') }} : </p>
                    </td>
                    <td>
                      <p>{{ salesSessionData.browser_print_details.approved_status }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{{ $t('ApprovedBy') }} : </p>
                    </td>
                    <td>
                      <p>{{ salesSessionData.browser_print_details.approved_by }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{{ $t('ApprovedAt') }} : </p>
                    </td>
                    <td>
                      <p>{{ salesSessionData.browser_print_details.approved_at | dateFormat }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{{ $t('LoginRequestAt') }} : </p>
                    </td>
                    <td>
                      <p>{{ salesSessionData.browser_print_details.login_requested_at | dateFormat }}</p>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="flex flex-row justify-center items-center">
                <vs-button
                           class="ml-2"
                           @click="hideSessionDetails" color="danger">
                  {{ $t('Close') }}
                </vs-button>
              </div>
            </div>
          </div>

        </div>
      </template>
    </vx-modal>

  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown.vue'
import VxModal from '@/layouts/components/custom/VxModal.vue'
import ReceiptPrint from '@/views/components/ReceiptPrint.vue'
import ReclaimPins from "@/views/components/ReclaimPins";

export default {
  name: 'SalesReportsView',
  components: {
    ReclaimPins,
    ReceiptPrint,
    Datepicker,
    VxModal,
    DynamicCustomersDropDown,
  },
  data() {
    return {
      isExcel: false,
      downloadEmail: "",
      showDownloadEmailModal: false,
      showPinBatch: false,
      pinDownload: false,
      pins: [],
      showDetailsModal: false,
      showDetails: false,
      mergeReceipts: false,
      showPrintModal: false,
      purchaseData: null,
      printReportType: null,
      cPage: 1,
      errorFetching: false,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Sales Reports', i18n: 'SalesReports', active: true},
      ],
      dynamicType: ['Soft API', 'EPay', 'International Top-Up', 'Direct Recharge', 'Gift card', 'Content Card PIN'],
      productsFilter: {},
      filterSelectData: {},
      selectedProduct: null,
      selectedCustomer: null,
      selectedProductConfigId: null,
      startDate: new Date(),
      endDate: new Date(),
      customerFilter: [],
      customer: {},
      selectedManager: null,
      selectedReferer: null,
      showReclaimPinsModal: false,
      showSessionDataModal: false,
      viewAll: false,
    }
  },
  computed: {

    usersRaw() {
      return this.$store.state.user.usersRaw || {}
    },
    staffAccounts() {
      if (this.selectedManager != null) {
        return this.selectedManager.staff_accounts
      } else {
        if(this.usersRaw) {
          return this.usersRaw.staff_accounts;
        }
        return []
      }
    },
    showMangerDropDown() {
      return this.activeUserInfo.internal_role === 0;
    },
    showRefererDropDown() {
      return this.activeUserInfo.internal_role === 0 || this.activeUserInfo.staff_role === 1;
    },

    disabledDates() {
      if (!this.startDate) {
        return {
          from: new Date(),}
      }
      return {
        to: this.startDate,
        from: new Date(),
      }
    },
    vPage() {
      return this.$store.state.reports.vPage
    },
    vTotalPages() {
      return this.$store.state.reports.vTotalPages
    },
    sales() {
      return this.$store.state.reports.sales
    },
    salesCounters() {
      return this.$store.state.reports.salesCounters
    },
    salesSessionData() {
      return this.$store.state.reports.salesRecordDetails
    },
    salesRaw() {
      return this.$store.state.reports.salesRaw
    },
    salesFilters() {
      return this.$store.state.reports.typeOfFilters
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.sales.length
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    onOpenDownloadEmailModal(isExcel = false) {
      if(this.activeUserInfo.account_role === 0) {

        this.showDownloadEmailModal = true;
        this.isExcel = isExcel
      } else {
        this.performDownloadSales(isExcel)
      }
    },
    onCloseDownloadEmailModal(email) {
      if(email) {
        this.performDownloadSales(this.isExcel);
      }
      this.showDownloadEmailModal = false;
    },
    exportSheets() {
      let data = [];
      this.sales.forEach(a => {
          let j = a.json_data;
          var i = 0;
          try {
            let ts = j.ticket_for_order.order.trips
            for(i = 0; i < ts.length; i++){
              let t = ts[i]
              let tt = j.get_passengers.trips[i]
              data.push({
                "organisation": a.organisation_name,
                "customer_id": a.customer_id,
                "email": j.email,
                "name": `${t.passengers[0].firstname} ${t.passengers[0].lastname}`,
                "phone": j.phone_no,
                "from": tt.to.name,
                "to": tt.from.name,
                "reservation_id": j.reservation_server_id,
                "payment_id": j.payment_id,
                "arrival": this.getFullTime(t.arrival.timestamp, t.arrival.tz),
                "arrival_new": this.getNewFullTime(t.arrival.timestamp, t.arrival.tz),
                "departure": this.getFullTime(t.departure.timestamp, t.departure.tz),
                "departure_new": this.getNewFullTime(t.departure.timestamp, t.departure.tz),
                "invoice_link": j.ticket_for_order.order.invoice_link
              })
            }
          } catch (e) {
            console.log(e)
          }

      })

    },
    getFullTime(milli, tz) {
      const d = new Date(milli * 1000)
      let t = tz.replace('GMT', '')
      const op = t[0]
      t = t.replace(op, '')
      const s = t.split(':')
      let m = moment(d.toUTCString()).add(Number(s[0]), 'hours')
      m = m.add(Number(s[1]), 'minutes')
      try {
        const options1 = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          timeZone: 'UTC'
        }
        const date = new Date(m.toISOString())
        const d = date.toLocaleString('de', { timeZone: 'Europe/Berlin' })
        const str2 = d.slice(0, -3) + '';
        return str2;
        // const dateTimeFormat = new Intl.DateTimeFormat('de', options1)
        // return dateTimeFormat.format(date)
      } catch (e) {
        return 'NA'
      }
    },
    getNewFullTime(milli, tz) {
      const d = new Date(milli * 1000)
      let t = tz.replace('GMT', '')
      const op = t[0]
      t = t.replace(op, '')
      const s = t.split(':')
      let m = moment(d.toUTCString()).add(Number(s[0]), 'hours')
      m = m.add(Number(s[1]), 'minutes')

      let timeOne = m.toISOString().split("T")
      let timeDate = timeOne[0].split("-")
      let timeTwo = timeOne[1].split(":")

      return `${timeDate[2]}.${timeDate[1]}.${timeDate[0]}, ${timeTwo[0]}:${timeTwo[1]}`;
    },
    reclaimPins(d) {
      const data = d;
      if(typeof(data.json_data) == "string") {
        data.json_data = JSON.parse(data.json_data)
      }
      // Direct Recharge
      if (data.inventory_type === 1 && (data.dynamic_type == 3 || data.dynamic_type == 8)) {
        this.printReportType = 3
        this.purchaseData = data.json_data.transaction_details
      }

      // GiftCards
      if (data.inventory_type === 0 && data.dynamic_type == 4) {
        data.json_data.product_name = data.product
        data.json_data.value = data.value
        this.purchaseData = data.json_data
        this.printReportType = 4
      } else if (data.inventory_type === 0) {
        this.printReportType = 0
        this.purchaseData = data.json_data
      }
      // CashCards
      if (data.inventory_type === 1 && (data.dynamic_type == 0 || data.dynamic_type == 9 )) {
        this.printReportType = 0
        if(data.json_data['type'] != undefined) {
          this.purchaseData = data.json_data
        } else {
          this.purchaseData = data
        }
        if(data.is_bulk_transaction) {
          this.purchaseData = data
        }
      }

      if (data.inventory_type === 1 && (data.dynamic_type == 1  || data.dynamic_type == 10)) {
        this.printReportType = 0
        this.purchaseData = data.json_data
        if(data.is_bulk_transaction) {
          this.purchaseData = data
        }
      }

      //Calling Cards
      if (data.inventory_type === 3) {
        this.printReportType = 0
        this.purchaseData = data.json_data
      }

      // GiftCards
      if (data.inventory_type === 1 && data.dynamic_type == 4) {
        data.json_data.product_name = data.product
        data.json_data.value = data.value
        this.purchaseData = data.json_data
        this.printReportType = 4
      }
      // International TopUp
      if (data.inventory_type === 1 && (data.dynamic_type == 2 || data.dynamic_type == 7)) {
        this.printReportType = 2
        this.purchaseData = data.json_data
      }
      // FlixBus
      if (data.inventory_type === 1 && data.dynamic_type == 5) {
        this.printReportType = 5
        this.purchaseData = data.json_data
      }
      this.purchaseData.transaction_id = data.transaction_id
      this.purchaseData.pins_and_batches = data.pins_and_batches;
      this.showReclaimPinsModal = true

    },
    showDownloadText(d) {
      let pType = -1
      const data = d;
      if(typeof(data.json_data) == "string") {
        data.json_data = JSON.parse(data.json_data)
      }

      // GiftCards
      if (data.inventory_type === 0 && data.dynamic_type == 4) {

      } else if (data.inventory_type === 0) {
        pType = 0
      }
      // CashCards
      if (data.inventory_type === 1 && (data.dynamic_type == 0 || data.dynamic_type == 9 )) {
        pType = 0
      }

      if (data.inventory_type === 1 && (data.dynamic_type == 1 || data.dynamic_type == 10)) {
        pType = 0
      }

      //Calling Cards
      if (data.inventory_type === 3) {
        pType = 0
      }

      return this.pinDownload && pType === 0;
    },
    closePinsModal() {
      this.showDetailsModal =false;
      this.pins = []
    },
    showPinsModal(tr) {
      this.pins = tr.pins_and_batches
      this.showDetailsModal = true;
    },
    closeModal() {
      this.showReclaimPinsModal = false
      this.showPrintModal = false
      this.purchaseData = null
      this.fetchSales(this.cPage - 1, this.getFilters())
    },
    printReport(d) {
      const data = d
      if(typeof(data.json_data) == "string") {
        data.json_data = JSON.parse(data.json_data)
      }
      // Direct Recharge
      if (data.inventory_type === 1 && data.dynamic_type === 3) {
        this.printReportType = 3
        this.purchaseData = data.json_data.transaction_details
        this.purchaseData.created = data.created;
      }

      // GiftCards
      if (data.inventory_type === 0 && data.dynamic_type === 4) {
        data.json_data.product_name = data.product
        data.json_data.value = data.value
        this.purchaseData = data.json_data
        this.printReportType = 4
      } else if (data.inventory_type === 0) {
        this.printReportType = 0
        this.purchaseData = data.json_data
      }
      // CashCards
      if (data.inventory_type === 1 && (data.dynamic_type == 0 || data.dynamic_type == 9)) {
        this.printReportType = 0
        if(data.json_data['type'] != undefined) {
          this.purchaseData = data.json_data
        } else {
          this.purchaseData = data
        }
      }
      if (data.inventory_type === 1 && (data.dynamic_type === 1  || data.dynamic_type == 10)) {
        this.printReportType = 0
        this.purchaseData = data.json_data
      }
      //Calling Cards
      if (data.inventory_type === 3) {
        this.printReportType = 0
        this.purchaseData = data.json_data
      }
      // GiftCards
      if (data.inventory_type === 1 && data.dynamic_type === 4) {
        data.json_data.product_name = data.product
        data.json_data.value = data.value
        this.purchaseData = data.json_data
        this.printReportType = 4
      }
      // International TopUp
      if (data.inventory_type === 1 && data.dynamic_type === 2) {
        this.printReportType = 2
        this.purchaseData = data.json_data
      }
      // FlixBus
      if (data.inventory_type === 1 && data.dynamic_type === 5) {
        this.printReportType = 5
        this.purchaseData = data.json_data
      }
      this.showPrintModal = true
    },
    performDownloadSales(isExcel = false) {
      this.downloadSales(false, this.getFilters(), isExcel)
    },
    onCustomerFilterChanged(c) {
      this.customer = c
      this.filterChanged()
    },

    viewAllReportData() {
      this.viewAll = !this.viewAll;

      this.filterChanged()
    },
    onCustomerTextChanged(value) {
      console.log(value)
      const searchText = value.srcElement.value
      const filter = {
        search_text: searchText,
      }
      this.$vs.loading()
      return this.$store.dispatch('reports/searchCustomers', {filter})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type === 1) {
            this.customerFilter = data.customers
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    onProductFilterChanged(resetRefer) {
      if(resetRefer === -1) {
        this.selectedReferer = null
      }
      this.filterChanged()
    },
    clearFilterData() {
      this.filterSelectData = {}
      this.selectedCustomer = null
      this.customer = {}
      this.startDate = null
      this.endDate = null
      this.selectedManager = null
      this.selectedReferer = null
      this.selectedProduct = null
      this.selectedProductConfigId = null
      this.filterChanged()
    },
    clearFilter(key) {
      this.filterSelectData[key] = null
      this.filterChanged()
    },
    getFilters() {
      const filter = {}
      Object.keys(this.filterSelectData).forEach((key) => {
        if (this.filterSelectData[key] != null) {
          filter[key] = this.filterSelectData[key].replace(key, '')
        }
      })
      if (this.startDate) {
        filter.start_date = moment(this.startDate).format('yyyy-MM-DD')
      }
      if (this.endDate) {
        filter.end_date = moment(this.endDate).format('yyyy-MM-DD')
      }

      if (this.selectedManager) {
        filter.refer_id = this.selectedManager.user_id
      }
      if (this.selectedReferer) {
        filter.refer_id = this.selectedReferer
      }

      if (this.selectedProduct) {
        filter.product_id = this.selectedProduct.product_id
      }
      if (this.selectedProductConfigId) {
        filter.product_configuration_id = this.selectedProductConfigId.replace('product_configuration_id', '')
      }
      if (Object.keys(this.customer).length > 0) {
        filter.customer_id = this.customer.customer_id
      }
      if(this.viewAll) {
        filter['view_all'] = this.viewAll ? 1 : 0;
      }
      return filter
    },
    filterChanged() {
      this.fetchSales('0', this.getFilters())
    },

    listPageChanged() {
      this.fetchSales(this.cPage - 1, this.getFilters())
    },
    fetchSales(page = '0', filter = {}) {
      this.fetchSalesCounter(page, filter);
      this.$vs.loading()
      return this.$store.dispatch('reports/fetchSalesReports', {page, filter})
        .then(() => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchSalesCounter(page = '0', filter = {}) {
      return this.$store.dispatch('reports/fetchSalesReportCounters', {page, filter})
        .then(() => {
          this.errorFetching = false
        })
        .catch((error) => {
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    showSessionDetails(sale) {
      this.fetchSalesReportSessions(sale.transaction_id);
    },
    hideSessionDetails(sale) {
      this.$store.commit('reports/SET_SALES_RECORD_DETAILS', {});
      this.showSessionDataModal = false;
    },
    fetchSalesReportSessions(transactionId) {
      this.$vs.loading()
      return this.$store.dispatch('reports/fetchSalesReportSessions', { transaction_id: transactionId})
        .then(() => {
          this.$vs.loading.close()
          this.showSessionDataModal = true;
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    downloadSales(isMore = false, f = {}, isExcel = false) {
      const filter = f
      this.$vs.loading()
      filter.download = 1
      if (isExcel) {
        filter.download_type = 'excel'
      }
      if(this.downloadEmail) {
        filter.download_email = this.downloadEmail;
        this.downloadEmail = "";
      }
      return this.$store.dispatch('reports/fetchDownloadSalesReports', {isMore, filter})
        .then((data) => {
          this.$vs.loading.close()

          this.downloadEmail = "";
          try {
            if(data.download_id) {
              this.$vs.notify({
                position: 'top-center',
                title: 'Success',
                text: data.message,
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'success',
              })
            } else {
              if(isExcel) {
                const hiddenElement = document.createElement('a')
                hiddenElement.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
                hiddenElement.target = '_blank'
                hiddenElement.download = 'sales_report.xlsx';
                hiddenElement.click()
              } else {
                const hiddenElement = document.createElement('a')
                hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`
                hiddenElement.target = '_blank'
                hiddenElement.download = 'sales_report.txt';
                hiddenElement.click()
              }
            }
          } catch (e) {
            if(isExcel) {
              const hiddenElement = document.createElement('a')
              hiddenElement.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
              hiddenElement.target = '_blank'
              hiddenElement.download = 'sales_report.xlsx';
              hiddenElement.click()
            } else {
              const hiddenElement = document.createElement('a')
              hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`
              hiddenElement.target = '_blank'
              hiddenElement.download = 'sales_report.txt';
              hiddenElement.click()
            }
          }

        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    loadReferer() {
      this.$vs.loading()
      this.$store.dispatch('user/fetchUsers', {})
        .then((data) => {
          this.errorFetching = false
        })
        .catch((err) => {
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchProductsFilter() {
      this.$store.dispatch('reports/fetchProductFilters').then((data) => {
        if (data.message_type === 1) {
          try {
            this.productsFilter = data.type_of_filters.products
          } catch (e) {
            this.productsFilter = []
          }
        }
      })
    }
  },
  created() {
    this.fetchProductsFilter();
    // this.endDate = new Date();
    // this.startDate = new Date();
    this.$store.commit('reports/RESET')
    // this.fetchSales()

    if (localStorage.getItem('merge_receipts') == null) {
      localStorage.setItem('merge_receipts', 'false')
    }
    this.loadReferer();
    this.mergeReceipts = localStorage.getItem('merge_receipts') === 'true'
    this.pinDownload = localStorage.getItem('pin_download') == 'true'
  },
}
</script>

<style lang="scss">

#sales-reports-view {

  .header-table {
    width: auto;

    th {
      font-weight: normal;
      font-size: 14px;
      min-width: 200px;
    }

    td {
      font-weight: bold;
      font-size: 24px;
      color: rgba(var(--vs-primary), 1);
    }
  }

  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .products-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
  .pins-table {
    border: 1px solid #000000;
    font-size: 18px;
    td {
      padding: 5px;
      text-align: center;
      border: 1px solid #000000;
    }
    th {
      padding: 5px;
      text-align: center;
      border: 1px solid #000000;
    }
  }
  .top-details-table {
    text-align: left;
    td {
      padding: 4px 0px;
    }
    td, th {
      border: 1px solid #ddd;
      padding: 8px;
    }

    tr:nth-child(even){background-color: #f2f2f2;}

    tr:hover {background-color: #ddd;}

    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #04AA6D;
      color: white;
    }
  }
  //.session-data-table {
  //  @apply border-2 border-black w-full;
  //}
}
</style>
